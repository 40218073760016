<template>
  <!-- 集采商城 -->
  <Layout>
    <div class="main-shop">
      <div class="base-width">
        <Search type shopCart record v-ani.fade-up />
      </div>
      <div class="c-layout-3">
        <div class="base-width">
          <div class="c-layout-3__lf">
            <!-- 产品分类 -->
            <ProductCate :catArr="catArr" />
            <!-- 精选商品 -->
            <ProductSelected :list="selectProduArr" />
          </div>
          <div class="c-layout-3__main">
            <!-- 广告图 -->
            <Banner class="img" :list="bannerList" height="4.92rem" v-ani.fade-up />
            <!-- 参数 -->
            <ProductParams :baseObj="baseObj" @change="seachFn" v-ani.fade-up />
            <!-- 筛选 -->
            <ProductFilter :totalNum="goodsTotal" @change="filterFn" v-ani.fade-up />
            <!-- 产品列表 -->
            <div class="md-prod-list">
              <template v-for="item in goodsArr">
                <ProductItem :value='item' :key="item.goods_id" v-ani.fade-up />
              </template>
            </div>
            <!-- 分页 -->
            <Pagination v-show="goodsTotal > 0" :page="goodsData.p" :size="goodsData.page_num" :total="goodsTotal"
              v-ani.fade-up @change="changePage" :key="pagKey" />
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { SelectedProductsApi, GoodsListApi, IndexBaseApi, IndexCatListApi } from "@/request/api/shop";
import { IndexBannerApi } from "@/request/api/user";
export default {
  created() {
    let cat_name = this.$route.query.cat_name;
    if (cat_name) {
      this.goodsData.cat_name = cat_name;
    }
    // 分类
    IndexCatListApi().then(({ status, data }) => {
      if (status == 200) {
        this.catArr = data;
      }
    })
    // 精选商品
    SelectedProductsApi().then(({ status, data }) => {
      if (status == 200) {
        this.selectProduArr = data.list;
      }
    })
    // 基础数据
    IndexBaseApi({ cat_name: this.$route.query.cat_name }).then(({ status, data }) => {
      if (status == 200) {
        this.baseObj = data;
      }
    })
    // 轮播
    IndexBannerApi({ from: 426 }).then(({ status, data }) => {
      if (status == 200) {
        this.bannerList = data;
      }
    })
    this.goodsFn();

  },
  data() {
    return {
      bannerList: [],
      catArr: [],
      selectProduArr: [],  // 精选商品
      goodsData: {
        p: 1,
        page_num: 12,
        material_type: "不限",
        material: "不限",
        limit_buy: "不限",
        brand: "不限",
        address: "不限",
        keyword: "",
        order: 1,
        sort: "",
        min_price: "",
        max_price: "",
        cat_name: ""
      },
      goodsArr: [],
      goodsTotal: 0,
      baseObj: {},
      pager: {
        page: 1,
        size: 12,
        total: 0,
      },
      pagKey: 0
    };
  },
  methods: {

    // 搜索
    seachFn(obj) {
      if (obj.typeNum == 1) {
        this.goodsData.material_type = obj.val;
      }
      if (obj.typeNum == 2) {
        this.goodsData.material = obj.val;
      }
      if (obj.typeNum == 3) {
        this.goodsData.limit_buy = obj.val;
      }
      if (obj.typeNum == 4) {
        this.goodsData.brand = obj.val;
      }
      if (obj.typeNum == 5) {
        this.goodsData.address = obj.val;
      }
      if (obj.typeNum == 6) {
        this.goodsData.cat_name = obj.val;
      }
      this.goodsData.p = 1;
      this.goodsFn();
    },
    // 参数筛选
    filterFn(obj) {
      this.goodsData.p = 1;
      this.goodsData.order = obj.order;
      this.goodsData.sort = obj.sort;
      this.goodsData.min_price = obj.min_price;
      this.goodsData.max_price = obj.max_price;
      this.goodsFn();
    },
    // 商品列表
    goodsFn() {
      GoodsListApi(this.goodsData).then(({ status, data }) => {
        if (status == 200) {
          this.goodsArr = data.list;
          this.goodsTotal = Number(data.total);
          this.pagKey++;
        }
      })
    },
    // 分页
    changePage(page) {
      this.goodsData.p = page;
      this.goodsFn();
      window.scroll({ top: 500, behavior: "smooth", });
    },
  }
};
</script>

<style lang="scss" scoped>
.main-shop {
  padding-bottom: .6rem;

  .md-pagination {
    margin-top: .4rem;
  }

  .md-prod-list {
    margin-top: .2rem;
    display: flex;
    flex-wrap: wrap;

    .c-product-item {
      width: 3rem;
      margin-bottom: .2rem;
      margin-right: .2rem;

      &:nth-of-type(4n) {
        margin-right: 0;
      }
    }
  }
}
</style>